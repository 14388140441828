import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import './icons';

import Loader from '../visual/Loader';
import Header from '../structure/header/Header';
import Footer from '../structure/footer/Footer';

import './base.scss';
import GoogleAnalytics from './GoogleAnalytics';

export class App extends Component {
    loadPage = page =>
        Loadable({
            loader: () => import(`../pages/${page}`),
            loading: Loader
        });

    componentDidMount() {
        if (typeof window != 'undefined') {
            this.props.history.listen((location, action) => {
                window.scrollTo(0, 0);
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet titleTemplate="%s | Appristas" defaultTitle="Appristas" />
                <Header />
                <main role="main">
                    <Switch>
                        <Route path="/about" exact component={this.loadPage('PageAbout')} />
                        <Route path="/services" exact component={this.loadPage('PageServices')} />
                        <Route path="/thank-you" exact component={this.loadPage('PageThankYou')} />
                        <Route path="/:page" exact component={this.loadPage('PageStatic')} />
                        <Route path="/" exact component={this.loadPage('PageHome')} />
                        <Route component={this.loadPage('Page404')} />
                    </Switch>
                </main>
                <Footer />
                <GoogleAnalytics />
            </Fragment>
        );
    }
}

export default withRouter(App);
