import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container, Row } from '../../grid';
import HeaderNav from './HeaderNav';

import styles from './header.module.scss';

import { ReactComponent as Logo } from '../../assets/img/logo-header.svg';

const Header = () => (
    <header className={styles.header}>
        <Container>
            <Row>
                <div className={styles.colLogo}>
                    <NavLink exact to="/" className={styles.logo} activeClassName={styles.active}>
                        <Logo />
                        <span className="sr-only">Appristas</span>
                    </NavLink>
                </div>
                <div className={styles.colNav}>
                    <HeaderNav />
                </div>
            </Row>
        </Container>
    </header>
);

export default Header;
