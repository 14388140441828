import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './header.module.scss';

const HeaderNav = () => (
    <nav role="navigation" className={styles.navigation}>
        <ul>
            <li>
                <NavLink to="/about" activeClassName={styles.active}>
                    about us
                </NavLink>
            </li>
            <li>
                <NavLink to="/services" activeClassName={styles.active}>
                    services
                </NavLink>
            </li>
        </ul>
    </nav>
);

export default HeaderNav;
