import React from 'react';
import { node, string } from 'prop-types';

import styles from './grid.module.scss';

const Container = React.forwardRef(({ children, className }, ref) => (
    <div ref={ref} className={`${styles.container} ${className}`}>
        {children}
    </div>
));

Container.propTypes = {
    children: node,
    className: string
};

Container.defaultProps = {
    children: null,
    className: ''
};

export default Container;
