import loadPolyfills from './polyfill';

import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app';

import { TweenLite, Power0 } from 'gsap';

TweenLite.defaultEase = Power0.easeInOut;

(async function() {
    await loadPolyfills();
    const rootElement = document.getElementById('root');
    if (rootElement.hasChildNodes()) {
        hydrate(
            <BrowserRouter>
                <App />
            </BrowserRouter>,
            rootElement
        );
    } else {
        render(
            <BrowserRouter>
                <App />
            </BrowserRouter>,
            rootElement
        );
    }
})();
