import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faMediumM, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faEnvelope,
    faMobile,
    faCircleNotch,
    faCheckCircle,
    faSadTear,
    faRedo,
    faChevronRight,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faFacebook,
    faMediumM,
    faGithub,
    faLinkedin,
    faEnvelope,
    faSadTear,
    faMobile,
    faRedo,
    faCircleNotch,
    faCheckCircle,
    faChevronRight,
    faTimes
);
