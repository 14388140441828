import React from 'react';
import { node, string, bool } from 'prop-types';

import styles from './grid.module.scss';

const Row = React.forwardRef(({ children, as, className, reverse }, ref) => {
    let classes = `${styles.row} ${className}`;
    if (reverse) {
        classes = `${classes} ${styles.rowReverse}`;
    }
    if (as) {
        return React.createElement(as, {
            ref,
            children,
            className: classes
        });
    } else {
        return (
            <div ref={ref} className={classes}>
                {children}
            </div>
        );
    }
});

Row.propTypes = {
    children: node,
    as: node,
    className: string,
    reverse: bool
};

Row.defaultProps = {
    children: null,
    as: null,
    className: '',
    reverse: false
};

export default Row;
