export default function() {
    const polyfills = [];

    if (!supportsIntersectionObserver()) {
        polyfills.push(import('intersection-observer'));
    }

    // MS Edge <= 15 does not support NodeList forEach method
    if (!NodeList.prototype.forEach && Array.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    return Promise.all(polyfills);
}

function supportsIntersectionObserver() {
    return (
        'IntersectionObserver' in global &&
        'IntersectionObserverEntry' in global &&
        'intersectionRatio' in IntersectionObserverEntry.prototype
    );
}
