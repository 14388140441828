import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends Component {
    componentDidMount() {
        /* eslint-disable */
        (function(i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            (i[r] =
                i[r] ||
                function() {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        /* eslint-enable */

        if (window.ga) {
            window.ga('create', process.env.REACT_APP_GA_PROPERTY_ID, 'auto');
            window.ga('set', { page: this.props.location.pathname, title: document.title });
            window.ga('send', 'pageview');
        }

        this.historyUnlisten = this.props.history.listen(location => {
            if (window.ga) {
                window.ga('set', { page: this.props.location.pathname, title: document.title });
                window.ga('send', 'pageview');
            }
        });
    }

    componentWillUnmount() {
        this.historyUnlisten();
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);
