import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Row, Col } from '../../grid';

import styles from './footer.module.scss';

const Footer = () => {
    const social = [
        { url: 'https://medium.com/appristas/', name: 'Medium', icon: 'medium-m' },
        { url: 'https://github.com/appristas/', name: 'Github', icon: 'github' },
        {
            url: 'https://www.linkedin.com/company/appristas/',
            name: 'LinkedIn',
            icon: 'linkedin'
        },
        { url: 'https://www.facebook.com/appristas/', name: 'Facebook', icon: 'facebook' }
    ];

    const links = [{ url: '/terms-and-conditions', title: 'Terms and Conditions' }];
    return (
        <footer className={styles.footer}>
            <Container>
                <Row className={styles.row}>
                    <Col className={styles.social}>
                        <ul>
                            {social.map(item => (
                                <li key={item.name}>
                                    <a
                                        rel="external noopener noreferrer"
                                        target="_blank"
                                        href={item.url}
                                    >
                                        <FontAwesomeIcon icon={['fab', item.icon]} size="lg" />
                                        <span className="sr-only">{item.name}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col className={styles.menu}>
                        <ul>
                            <li>
                                <small>&copy; Appristas Inc. 2018</small>
                            </li>
                            {links.map(link => (
                                <li key={link.title}>
                                    <Link to={link.url}>{link.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className={styles.version}>
                <small aria-label="version">v1.4</small>
            </div>
        </footer>
    );
};

export default Footer;
