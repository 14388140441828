import React from 'react';
import { number, node, string } from 'prop-types';

import styles from './grid.module.scss';

const Col = ({ children, grow, as, className }) =>
    as ? (
        React.createElement(as, {
            children,
            className: `${styles[`col-${grow}`]} ${className}`
        })
    ) : (
        <div className={`${styles[`col-${grow}`]} ${className}`}>{children}</div>
    );

Col.propTypes = {
    grow: number,
    children: node,
    className: string,
    as: string
};

Col.defaultProps = {
    grow: 1,
    children: null,
    className: '',
    as: null
};

export default Col;
