import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './loader.module.scss';

export default class Loader extends Component {
    render() {
        if (this.props.error) {
            return (
                <div className={styles.loader}>
                    <div className={styles.fail}>
                        <FontAwesomeIcon icon="sad-tear" size="5x" />
                        <h4>oops... something went wrong</h4>
                        <button onClick={this.props.retry}>
                            <FontAwesomeIcon icon="redo" /> Retry
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.loader}>
                <div className={styles.wave}>
                    <div className={styles.smoke1} />
                    <div className={styles.smoke2} />
                    <div className={styles.smoke3} />
                </div>
            </div>
        );
    }
}
